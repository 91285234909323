@use "src/common/styles/variables" as *;
@use "src/common/styles/resetMixins" as *;

.container {
  @include bp-all {
    @include reset-button;
    width: 100%;
    display: block;
    padding: 0 $spacingM;
    font-weight: 600;
    text-transform: uppercase;

    &_base {
      background: $bgButton;

      &:hover {
        background: darken($bgButton, 5%);
      }

      &:active {
        outline: none;
        box-shadow: 0 0 0 2px darken($bgButton, 15%);
      }

      &[disabled] {
        color: $textColorFaded;
        background: $bgButtonFaded;
      }
    }

    &_delete {
      color: $white;
      background: $bgDeleteButton;

      &:hover {
        background: darken($bgDeleteButton, 5%);
      }

      &:active {
        outline: none;
        box-shadow: 0 0 0 2px lighten($bgDeleteButton, 15%);
      }

      &[disabled] {
        color: lighten($textColorFaded, 10%);
        background: $bgDeleteButtonFaded;
      }
    }
  }

  @include bp-between(0, $mobile) {
    height: 30px;
    font-size: 0.8rem;
    border-radius: $borderRadiusS;
  }

  @include bp-between($mobile, $tablet) {
    height: 35px;
    font-size: 0.9rem;
    border-radius: $borderRadiusM;
  }

  @include bp-from($tablet) {
    height: 40px;
    border-radius: $borderRadiusL;
  }
}
