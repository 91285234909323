@use "../../../common/styles/variables" as *;
@use "../../../common/styles/resetMixins" as *;

.container {
  @include bp-all {
    display: flex;
    position: relative;
  }

  .button {
    @include reset-button;

    @include bp-all {
      overflow: hidden;
      flex: 0 0 auto;
    }

    @include bp-between(0, $mobile) {
      width: 20px;
      height: 20px;
    }

    @include bp-from($mobile) {
      width: 25px;
      height: 25px;
    }

    &__icon {
      width: 100%;
      height: 100%;
      fill: #2d3448;
    }
  }
}
