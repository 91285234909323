// COLORS
$black: #000;
$white: #fff;

$gray100: #e0e1e5;
$gray300: #969aa4;
$gray700: #394155;
$gray800: #2d3448;

$alabaster300: #edeee7;
$alabaster700: #bcc8b8;

$beige: #fffaf3;

$blue: #d4e9ec;

$yellow400: #f9d875;
$yellow600: #ffc32d;

$red50: #efbbbb;
$red200: #fd616f;
$red300: #ff5160;
$red500: #FC293B;

// PALETTE
$bgMain: $beige;
$bgPopup: $white;
$bgFooter: $gray800;
$bgButton: $blue;
$bgDeleteButton: $red200;
$bgButtonFaded: $gray100;
$bgDeleteButtonFaded: $red50;
$bgNews: $white;
$textColor: $gray800;
$textColorFaded: $gray300;
$userIcon: $gray700;
$adminIcon: $red300;
$activeLink: $red500;
$closeBtn: $red500;
$errorHintText: $red500;
$shadowColor: $gray800;
$borderColor: $gray800;
$activeBorderColor: $blue;
$newsBorderColor: $gray100;

// SPACING
$spacingXS: 5px;
$spacingS: 10px;
$spacingM: 15px;
$spacingL: 20px;

// BORDER RADII
$borderRadiusS: 4px;
$borderRadiusM: 6px;
$borderRadiusL: 8px;

// BREAKPOINTS
$mobile: 360px;
$tablet: 768px;
$desktop: 1170px;


@mixin bp-all {
  @media screen {
    @content;
  }
}

@mixin bp-from($type) {
  @media screen and (min-width: $type) {
    @content;
  }
}

@mixin bp-till($type) {
  @media screen and (max-width: $type - 0.1) {
    @content;
  }
}

@mixin bp-between($type-from, $type-to) {
  @media screen and (min-width: $type-from) and (max-width: $type-to - 0.1) {
    @content;
  }
}
