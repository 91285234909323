@use "src/common/styles/variables" as *;
@use "src/common/styles/resetMixins" as *;

.label {
  @include bp-all {
    font-weight: 600;
    margin-bottom: $spacingXS;
  }

  @include bp-between(0, $mobile) {
    font-size: 0.7rem;
    margin-top: $spacingS;
  }

  @include bp-between($mobile, $tablet) {
    font-size: 0.75rem;
    margin-top: $spacingM;
  }

  @include bp-from($tablet) {
    font-size: 0.8rem;
    margin-top: $spacingL;
  }
}

.textarea {
  @include bp-all {
    @include reset-input-base;
    width: 100%;
    display: block;
    background: $white;
    border: 1px solid $borderColor;
    resize: vertical;
    overflow: auto;

    &:focus {
      border-width: 2px;
      border-color: $activeBorderColor;
    }

    @include bp-between(0, $mobile) {
      min-height: 200px;
      font-size: 0.8rem;
      padding: 0 $spacingXS;
      margin-bottom: $spacingS;
      border-radius: $borderRadiusS;
    }

    @include bp-between($mobile, $tablet) {
      min-height: 250px;
      font-size: 0.9rem;
      padding: 0 $spacingS;
      margin-bottom: $spacingM;
      border-radius: $borderRadiusM;
    }

    @include bp-from($tablet) {
      min-height: 275px;
      padding: 0 $spacingS;
      margin-bottom: $spacingL;
      border-radius: $borderRadiusL;
    }
  }
}
