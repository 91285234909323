@use "../../../../common/styles/variables" as *;
@use "../../../../common/styles/resetMixins" as *;

.container {
  @include bp-all {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $bgPopup;
    border-radius: $borderRadiusL;
    box-shadow: -1px 1px 2px 0px $shadowColor;
    z-index: 1;

    .closeButton {
      @include reset-button;
      position: absolute;
      overflow: hidden;
      flex: 0 0 auto;
      border-radius: 100%;

      &__icon {
        width: 100%;
        height: 100%;
        fill: $closeBtn;

        &:hover {
          fill: darken($closeBtn, 15%);
        }
      }

      &:active {
        outline: none;
        box-shadow: 0 0 0 2px lighten($closeBtn, 15%);
      }
    }

    .form,
    .loggedUser {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .error {
      color: $errorHintText;
      font-weight: 600;
      text-align: center;
    }

    .userIcon {
      overflow: hidden;

      .icon {
        width: 100%;
        height: 100%;
      }

      &_user {
        fill: $userIcon;
      }

      &_admin {
        fill: $adminIcon;
      }
    }

    .userName {
      font-weight: 600;
      text-align: center;
      text-transform: capitalize;
    }
  }

  @include bp-between(0, $mobile) {
    width: 200px;
    height: 210px;
    padding: $spacingS;

    .closeButton {
      width: 20px;
      height: 20px;
      top: $spacingS;
      right: $spacingS;
    }

    .error {
      font-size: 0.8rem;
      margin-top: $spacingXS;
    }

    .userIcon {
      width: 100px;
      height: 100px;
      margin: 20px auto 0;
    }

    .userName {
      margin-top: $spacingXS;
    }
  }

  @include bp-between($mobile, $tablet) {
    width: 250px;
    height: 260px;
    padding: $spacingM;

    .closeButton {
      width: 25px;
      height: 25px;
      top: $spacingM;
      right: $spacingM;
    }

    .error {
      font-size: 0.9rem;
      margin-top: $spacingS;
    }

    .userIcon {
      width: 115px;
      height: 115px;
      margin: 30px auto 0;
    }

    .userName {
      margin-top: $spacingS;
      font-size: 1.1rem;
    }
  }

  @include bp-from($tablet) {
    width: 270px;
    height: 300px;
    padding: $spacingL;

    .closeButton {
      width: 25px;
      height: 25px;
      top: $spacingL;
      right: $spacingL;
    }

    .error {
      margin-top: $spacingS;
    }

    .userIcon {
      width: 130px;
      height: 130px;
      margin: 30px auto 0;
    }

    .userName {
      margin-top: $spacingS;
      font-size: 1.2rem;
    }
  }
}
