@use "src/common/styles/main";
@use "src/common/styles/variables" as *;

.container {
  @include bp-all {
    background-color: $bgMain;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .content {
      flex: 1 0 auto;
    }
  }

  @include bp-from($tablet) {
    max-width: $desktop;
    margin: 0 auto;
  }
}
